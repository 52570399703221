body {
  background-color: "#102E4A" !important;;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#model{
  margin: 1em;
  padding: 1em;

}

#gohere{
  width: 100%;
  height: 100%;
  margin: 1em;
  padding: 1em;
  position: static; /* fixed or static */
  top: 100px;
  left: 100px;
  justify-items: center;
}

#test {
  width: 420px; /*400px*/
  height: 420px; /*500px*/
  position: static; /* fixed or static */
  justify-content: center;
  top: 100px;
  left: 100px;
}

canvas {
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.col {
 display: flex;
 flex-direction: column;
 justify-content: space-between;
}

.left{
  float: left;
  width: 15%;
}

.right {
    float: right;
    width: 15%;
}

.middle {
  float: center;
  width: 50%;
}
